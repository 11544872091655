@import "src/App";

.model-detail-header {
  $data-left-right-padding: 16px;
  background: white;
  padding-bottom: 32px;

  &__jump-link {
    margin-top: 24px;
    @include bodySmall;
    color: $white;
    display: flex;
    align-items: center;
    text-decoration: underline;
    transition: 0.25s;

    &:hover {
      color: $electricGreen;

      .model-detail-header__jump-link-icon {
        animation: 750ms bounceUpAndRotate cubic-bezier(1, -0.13, .32, .89);
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      transform: rotate(90deg);

      path {
        fill: $electricGreen;
      }
    }
  }

  &__title-box {
    @include paddingGutters;
    background: url('../../resources/img/round-splash.jpg') no-repeat;
    background-size: cover;
    color: white;
    padding-top: 64px;
    padding-bottom: 48px;
  }

  &__date-container {
    display: flex;
    margin-bottom: 16px;
  }

  &__date-label {
    @include labelSmall;
  }

  &__date {
    @include bodySmall;
    line-height: 20px;
    margin-left: 8px;
    color: white;
  }

  &__model-name {
    @include headline1;
    margin-bottom: 16px;
    overflow-wrap: break-word;
    @include tabletWidth {
      font-size: 48px;
      line-height: 52px;
    }
    @include mobileWidth-lg {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__model-description {
    @include body;
    color: white;
    overflow-wrap: break-word;
  }

  &__data-box {
    background-color: $smokeDarkest;
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 1fr);
    @include gutters;
    margin-top: 32px;

    @include maxWidth(1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__task-box,
  &__framework-box,
  &__machines-box,
  &__training-set-box {
    background-color: white;
    padding: 16px $data-left-right-padding;
    @include maxWidth(1000px) {
      padding-left: 0;
      padding-right: 0;
    }


  }

  &__machine-tags-box {
    display: flex;
  }

  &__task-description {
    @include bodySmall;
    color: $charcoalLightest;
    margin-top: 8px;
  }

  &__label {
    @include labelSmall;
    color: $charcoalLightest;
    margin-bottom: 12px;
  }
}
