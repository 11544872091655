@import "src/App";

.stepper-step {


  &__step {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;

    :hover, &:hover {
      color: $purple;
      transition: 0.25s;


      .stepper-step__circle {
        border-color: $purple;
      }
    }

    &:last-child .stepper-step__line {
      visibility: hidden;
    }
  }

  &__step-completed {

  }

  &__stepper {
    position: relative;
  }

  &__content {
    @include headline6;
    color: $azulDarkest;
    margin-top: 2px;
  }

  &__circle {
    background-color: white;
    border: 2px solid $azulDarkest;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: -4px;

    &--completed {
      visibility: visible;
      background-color: $electricGreen;
    }
  }

  &__line {
    top: 18px;
    left: 3px;
    height: 205%;
    position: absolute;
    border-left: 2px solid $azulDarkest;
  }


}
