@import "../../App";

.search-bar {
  position: relative;
  display: inline-flex;
  align-items: center;

  &__input {
    @include body;
    $magGlassSize: 18px;
    $magGlassLeftMargin: 16px;
    $leftPadding: $magGlassSize + $magGlassLeftMargin + 8px;
    background-image: url("../../resources/icons/magnifying-glass.svg");
    background-position: $magGlassLeftMargin center;
    background-repeat: no-repeat;
    background-size: $magGlassSize;
    padding: 16px 4px 16px $leftPadding;
    width: 100%;

    &:focus {
      outline: none !important;
      border-color: $azul;
    }
  }

  &__tag {
    &--visible {
      position: absolute;
      right: 20px;
      padding: 5px;
      background-color: $grayDarkest;
      border-radius: 100%;
      text-align: center;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;

      svg {
        width: 50%;

        path {
          fill: white;
        }
      }

    }

    &--hidden {
      display: none;
    }
  }
}
