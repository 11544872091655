@import "../../App";

.model-card-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  justify-content: stretch;
  gap: 16px;
  width: 100%;
  @include maxWidth(1450px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  @include maxWidth(715px) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.model-list-page {
  background-color: $white;

  &__filter-content {
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: 25;
    background-color: $white;
    top: 0;
    left: 0;
    padding: 20px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $smokeDark;
      font-weight: 800;
      font-size: 16px;
      padding-bottom: 30px;
      margin-bottom: 16px;

      &-title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: $charcoalLightest;

        svg {
          margin-right: 8px;

          path {
            fill: $charcoalLightest;
          }
        }
      }

      &-close {
        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $charcoalLightest;
          }
        }
      }
    }
  }

  &__content {
    @include gutters;
    display: grid;
    grid-template-areas:
      "control-panel header"
      "control-panel list";
    grid-template-columns: 300px 1fr;
    grid-column-gap: 64px;
    margin-top: 64px;
    padding-bottom: 64px;
    @include desktopWidth {
      display: block;
    }

    &-hidden {
      display: none;
    }

  }

  &__sort-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    @include mobileWidth-lg {
      .sort-button {
        width: 100%;
        margin-top: 8px;

        button {
          width: 100%;
        }
      }
    }
  }

  &__filters-search-container {
    grid-area: control-panel;
    @include desktopWidth {
      display: none;
    }
  }

  &__search-bar {
    margin-bottom: 36px;
  }

  &__list {
    grid-area: list;

    &-no-filters {

    }
  }
}
