@import "src/App";

.intro-tutorial-intro {
  width: 100%;
  min-height: 100vh;
  background-color: $richNavy;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__intro-text {
    text-align: center;
    color: $skyBlue;
    @include labelBold();
    margin-bottom: 14px;
  }

  &__title {
    text-align: center;
    @include billboard;
    color: $white;
    margin-bottom: 32px;
  }

  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateY(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }
}

