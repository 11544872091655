$skyBlue: #5FA9FF;
$azul: #1E63F2;
$azulDark: #0D4BCD;
$azulDarkest: #0439A6;
$azulLightest: #F8FAFF;
$richNavy: #031D60;
$purple: #7D31E2;
$almaMater: #1E3877;
$almaMaterLight: #4D69A0;
$altgeld: #C22C00;
$darkRed: #BF1319;
$charcoalLightest: #4E4E4E;
$gray: #B3B3B3;
$grayDark: #9F9F9F;
$grayDarker: #848484;
$grayDarkest: #6D6D6D;
$electricGreen: #59EAD8;
$electricGreenLight: #8CF8EB;
$mint: #54AF8F;
$mintLight: #7BDDBA;
$mintLightest: #97EECF;
$pink: #E48487;
$pinkLight: #FFA9AC;
$pinkLightest: #FFB5B7;
$smokeDarkest: #D6D6D6;
$smokeDarker: #E4E4E4;
$smokeDark: #F2F2F2;
$smoke: #F8F8F8;
$white: #FFFFFF;

$primaryGradient: linear-gradient(to bottom, rgba(125, 49, 226, 1) 0%, rgba(73, 76, 234, 1) 10%, rgba(30, 99, 242, 1) 50%, rgba(73, 76, 234, 1) 90%, rgba(125, 49, 226, 1) 100%);
$secondaryGradient: linear-gradient(180deg, rgba(125, 49, 226, 1) 7%, rgba(0, 114, 246, 1) 70%, rgba(103, 173, 255, 1) 100%);
$subtleGradient: linear-gradient(to bottom, $purple 0%, $azul 75%);
$richNavyGradient: linear-gradient(273.72deg, #021C60 12.06%, #012277 42.23%, #2D0E84 96.35%);
