@import "src/App";

.intro-tutorial-models-infographic {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  @include tabletWidth {
    flex-direction: column;
  }

  &__text-area {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-right: 24px;
    z-index: 1;
    padding: 24px;

    @include tabletWidth {
      margin-right: 0px;
      margin-left: 0px;
      width: unset;
      padding: 0;
      margin-bottom: 200px;
      align-items: center;
      text-align: center;
    }
  }

  &__models-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
    pointer-events: none;
    transform: translateX(0px) scale(.75);
    position: absolute;
    z-index: 0;
    width: 804px;
    right: 3%;
    top: -88px;

    @include maxWidth(1800px) {
      right: -5%;
    }

    @include maxWidth(1600px) {
      right: -10%;
    }

    @include maxWidth(1400px) {
      right: -15%;
    }

    @include maxWidth(1325px) {
      right: -24%;
    }
    @include maxWidth(1150px) {
      right: -11%;
    }
    @include maxWidth(1080px) {
      right: -32%;

    }

    @include tabletWidth {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 140%;
      top: 55%;
      left: -5%;

      transform: translateX(0px) scale(.9);

      .model-card {
        min-width: 300px;
      }
    }

  }

  &__intro-text {
    @include labelBold();
    color: $azul;
    margin-bottom: 12px;
  }

  &__title {
    @include billboardSmall;
    color: $richNavy;
    margin-bottom: 50px;
    text-align: left;

    @include tabletWidth {
      text-align: center;
    }
  }


  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");
    width: fit-content;
    @include maxWidth(1650px) {
      font-size: 16px;
      line-height: 24px;
    }
    @include mobileWidth-sm {
      line-height: 16px;
    }

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }
}
