@import "../../../../../App";

.top-prediction {
  align-items: center;
  background-color: $azulLightest;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 32px 64px;

  @include mobileWidth-lg {
    padding: 32px 32px;
  }

  &__prediction {
    @include bodyLarge;
    font-weight: bold;
    margin-bottom: 8px;
    text-transform: capitalize;
    text-align: center;
  }

  &__probability {
    //border-bottom: 1px solid $gray;
    color: $charcoalLightest;
    font-size: 48px;
    font-weight: 800;
    line-height: 40px;
    //margin-bottom: 16px;
    //padding-bottom: 24px;
    text-align: center;
    width: 100%;
  }
}
