@import "../../App";

.filter-panel {
  color: black;
  margin-top: 12px;

  &__header {
    @include label;
    color: $charcoalLightest;
    font-weight: bold;
    margin-bottom: 32px;
    text-transform: uppercase;
  }
}

.model-list-page__filter-content-header-close {
  path {
    transition: 0.5s;
  }

  &:hover {
    path {
      fill: $grayDarkest;
    }
  }
}
