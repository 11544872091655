@import "../../App";

.example-task-card {
  &__model-title {
    @include label;
    color: $richNavy;
    margin-bottom: 12px;
  }

  &__model-link {
    @include linkLarge;
    color: $azul;
    margin-bottom: 12px;

    &:hover {
      color: $purple;
    }
  }

  .quick-image-input {
    background-color: $white;
    box-shadow: none;
    padding: 0;


    &__tabs {
      background-color: $smoke;
      margin-top: 0;
    }


  }
}
