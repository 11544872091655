@import "src/App";


.intro-tutorial-plant-prompt {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  padding-top: 100px;
  padding-bottom: 100px;


  &__intro-text {
    @include labelBold();
    color: $azul;
    margin-bottom: 12px;
    text-align: center;

  }

  &__title {
    @include billboardSmall;
    color: $richNavy;
    margin-bottom: 50px;

    max-width: 800px;

    @include maxWidth(800px) {

    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: 464px;
    border: 2px solid $smokeDarker;

    &-wrapper {
      width: 60%;
      max-width: 480px;
      height: auto;
      position: relative;
      padding: 8px;
      border: 2px solid;
      box-sizing: border-box;
      border-image-source: linear-gradient(267.7deg, #7E30E1 35.68%, #0072F7 58.04%, #67ADFF 73.86%);
      border-image-slice: 1;
      margin-bottom: 40px;

      @include maxWidth(700px) {
        width: 80%;
      }

      @include mobileWidth-sm {
        width: 100%;
      }
    }
  }

  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }

}
