@import "src/App";

.text-upload {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__help-text {
    @include body;
    color: $charcoalLightest;
    margin-bottom: 24px;
  }
}