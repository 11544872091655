@import "../../App";

@mixin baseFilterButton($color) {
  &-active,
  &-inactive {
    @include headline6;
    border: solid 1px $color;
    border-radius: 23px;
    padding: 4px 8px;
    margin: 0 8px 8px 0;
    transition: background 0.3s;
  }

  &-active {
    background: $color;
    color: $white;

    .filter-group__button-icon {
      // Styling for .svg Task icons to make sure they are $white and $azul
      // Note: This needs to be double-checked on Staging / Local
      path {
        fill: $white;
      }
      line {
        stroke: $white;
      }
      rect {
        stroke: $white;
      }
      circle {
        stroke: $white;
        fill: $white;
      }
      polyline {
        stroke: $white;
      }                  
    }
  }

  &-inactive {
    background: white;
    color: $color;
    // Styling for .svg Task icons to make sure they are $white and $azul
    path {
      fill: $color;
    }
    line {
      stroke: $color;
    }
    rect {
      stroke: $color;
    }
    circle {
      stroke: $color;
      fill: $color;
    }
    polyline {
      stroke: $color;
    }    
    .a {
      stroke: $color;
    }   

    &:hover {
      background: rgba($color, 0.07);
    }
  }
}

@mixin activeFilterButton($color) {
  background: $color;
  color: $white;
}

@mixin inactiveFilterButton($color) {
  background: white;
  color: $color;

  &:hover {
    background: rgba($azul, 0.07);
  }
}

.filter-group {
  margin-bottom: 36px;

  &__header {
    @include headline3;
    color: $charcoalLightest;
    margin-bottom: 4px;
  }

  &__description {
    @include bodySmall;
    color: $charcoalLightest;
    margin-bottom: 12px;
  }

  &__button-frameworks {
    @include baseFilterButton($purple);
  }

  &__button-tasks {
    @include baseFilterButton($azul);
  }

  &__button-machines {
    @include baseFilterButton($altgeld)
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__button-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;

    &-machine {
      path {
        fill: $altgeld;
      }
    }
  }
}
