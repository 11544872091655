@import "src/App";

.task-banner {
  @include paddingGutters;
  width: 100%;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  background-color: rgba($azul, 0.05);

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__name {
    @include headline3;
    color: $azul;
  }
}
