@import "../../../App";

.object-detection-summary {

  &__header {
    @include labelSmall;
    color: $charcoalLightest;
    margin-bottom: 16px;
    border-top: 1px solid $smokeDarker;
    padding-top: 16px;
  }

  &__results {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;


  }

  .object-detection-table {
    max-height: unset !important;
  }

  .object-detection-image {
    &__options-box {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }


    &__options-area {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
