@import "../../../App";


.add-input-modal {
  &__heading {
    @include headline2;
    color: $azulDarkest;
    padding-left: 42px;
    width: 100%;

    @include maxWidth(900px) {
      padding-left: 0;
    }
  }

  &__input-wrapper {
    align-self: flex-start;
    min-width: 80vw;


    .quick-image-input {
      background: transparent;
      box-shadow: none;
      padding: 0 42px;

      @include maxWidth(900px) {
        padding: 0;
      }
    }
  }
}
