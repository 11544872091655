@import "../../../App";

.input-preview,
.multi-input-preview {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  flex: 1 1;
  height: fit-content;
  padding: 32px;

  &__title {
    margin-bottom: 16px;
  }

  &__image {
    display: block;
    max-width: 100%;

    @include desktopWidth {
      width: 100%;
    }
  }

  &__text {
    border: 2px solid $charcoalLightest;
    border-radius: 5px;
    margin-top: 16px;
    padding: 8px 16px;
  }

  &__video {
    max-width: 100%;
  }

  &__document {
    display: flex;
    align-items: end;
    justify-content: left;
    flex-grow: 1;
    border: 1px solid $gray;
    gap: 10px;
    padding: 7px 8px;
    text-align: start;

    &:not(:first-child) {
      margin-left: 4px;
    }

    .icon {
    }
    & a {
      color: $azul;
    }
  }

  &__csv {
    border: 1px solid $charcoalLightest;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px;
    font-weight: 600;
    color: $charcoalLightest;
    margin: 0 auto;
    text-decoration: none;
    display: inline-block;
    img {
        height: 50px;
    }
    p {
        margin-top: 5px;
        font-size: 12px;
    }
  }

  &__back-button {
    @include secondaryButtonWithIconBefore("arrow-right.svg");
    align-self: flex-start;
    justify-content: center;
    margin-top: 16px;

    @include mobileWidth-lg {
      width: 100%;
    }
  }
}
