@import "src/App";

.intro-tutorial-training-infographic {
  $colBreakpoint: 1360px;

  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: $white;

  &__intro-text {
    @include labelBold();
    color: $azul;
    margin-bottom: 12px;
    text-align: center;

  }

  &__title {
    @include billboardSmall;
    color: $richNavy;
    margin-bottom: 24px;

  }

  &__sub-title {
    @include body;
    color: $charcoalLightest;
    text-align: center;
    margin-bottom: 32px;

    @include maxWidth($colBreakpoint) {
      margin-bottom: 32px;
    }
  }

  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }

  &__training-graphic {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 170px;
    margin-bottom: 250px;


    @include maxWidth($colBreakpoint) {
      flex-direction: column;

      margin-bottom: 20px;
    }

    @include maxWidth(750px) {
      transform: scale(0.75);

      margin-top: -20px;
      margin-bottom: -72px;
    }


    &-arrow {
      transform: scale(0.8) translateY(-16px);

    }

    &-left {
      position: relative;
      width: 500px;
      height: 200px;

      @include maxWidth($colBreakpoint) {
        transform: rotate(90deg);
        margin-bottom: 150px;
      }

      @include mobileWidth-lg {
        width: 350px;
        margin-bottom: 80px;
      }

      &-background {
        position: absolute;
        top: -82px;
        left: 0;
        z-index: 0;
        width: 100%;
      }

      &-top-group, &-bottom-group {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include maxWidth($colBreakpoint) {
          transform: rotate(-90deg);
        }
      }

      &-top-group {
        position: absolute;
        bottom: 58%;
        left: 12%;
        @include maxWidth($colBreakpoint) {
          flex-direction: column-reverse;
        }

        @include mobileWidth-lg {
          bottom: 52%;
        }
        @include mobileWidth-sm {
          bottom: 37%;
        }

      }

      &-bottom-group {
        position: absolute;
        top: 58%;
        left: 12%;
        @include mobileWidth-lg {
          top: 52%;
        }

        @include mobileWidth-sm {
          top: 37%;
        }

      }

      &-text {
        color: $richNavy;
        @include headline5;
        max-width: 300px;
        overflow-wrap: break-word;
        margin: 12px 0;

        @include maxWidth($colBreakpoint) {
          background-color: $white;
          padding: 12px;
        }

        @include mobileWidth-lg {
          max-width: 200px;
          font-size: 16px;
        }

        @include mobileWidth-sm {
          max-width: 200px;
          font-size: 14px;
        }
      }
    }

    &-group {
      margin-left: 12px;
      margin-right: 12px;

      @include maxWidth($colBreakpoint) {
        margin: 12px 0;
      }

      &-text {
        @include headline5;
        color: $richNavy;
        text-align: center;
        margin: 16px 0;
      }
    }

    &-arrow {
      @include maxWidth($colBreakpoint) {
        transform: rotate(90deg);
      }
    }
  }


  &-image-group {
    background-color: $white;
    border: 2px solid $richNavy;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 280px;

    @include maxWidth($colBreakpoint) {
      width: 200px;
    }
    @include maxWidth(370px) {
      transform: scale(.9);
    }

  }
}
