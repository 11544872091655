@import "../../../../../App";


.image-enhancement-image {

  display: flex;
  justify-content: center;
  align-items: center;

  &__header-center {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__header-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 12px;

    &-text {
      @include headline4;
      color: $almaMater;


    }
  }

  &__wrapper {
    width: 800px;

    @include maxWidth(1050px) {
      width: 700px;
    }

    @include maxWidth(950px) {
      width: 500px;
    }

    @include maxWidth(700px) {
      width: 300px;
    }

    @include maxWidth(410px) {
      width: 225px;
    }


    &-tall {
      width: 300px;
      @include maxWidth(500px) {
        width: 200px;
      }
    }
  }

  &__output {
    position: relative;

    max-width: 100%;

    &-canvas {
      padding: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-height: 800px;
      overflow-y: auto;
      margin-bottom: 32px;

      @include tabletWidth {
        margin-bottom: 0;
      }

    }

    &-left, &-right {
      height: 100%;
      width: 100%;
      max-height: 500px;
      max-width: fit-content;
      object-fit: fill;
      position: absolute;
      margin: 0;
      padding: 0;
    }

    &-left {
      clip-path: polygon(0 0, var(--value) 0, var(--value) 100%, 0 100%);
    }

    &-right {
      clip-path: polygon(var(--value) 0, 100% 0, 100% 100%, var(--value) 100%);
    }


    &-range {
      background-color: transparent;
      box-sizing: border-box;
      font-family: inherit;
      height: 100%;
      margin: 0;
      outline: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
      border: none;
      cursor: ew-resize;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        border: none;
        height: 30px;
        width: 15px;
        border-radius: 5px;
        background: transparent !important;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -11px;
      }

      &::-moz-range-thumb, &::-moz-range-progress, &::-moz-range-track, &::-webkit-slider-container {
        display: none;
        background: transparent;
        border: none;
        -webkit-appearance: none;
      }
    }

    &-bar {
      height: 100%;
      width: 10px;
      left: var(--value);
      background-color: $white;
      z-index: 4;
      position: absolute;

      &-thumb {
        position: absolute;
        top: 45%;
        left: -16px;
        width: 40px;
        height: 40px;
        z-index: 10;
      }

    }
  }
}
