@import "../../App";

.experiment-detail-page {
  &__sticky-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: $almaMater;
    padding: 32px;
  }

  &__content {
    @include gutters;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 64px;
    margin-top: 42px;
    padding-bottom: 96px;
    @include desktopWidth {
      display: flex;
      flex-direction: column;
    }
  }

  &__trial-cards {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    background: $white;
    padding: 20px 12px;
    transform: rotate(180deg);

    @include tabletWidth {
      flex-direction: column;
      transform: none;
      overflow-x: hidden;
      align-items: center;
    }

    @include maxWidth(550px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__trials-header {
    @include headline2;
    color: $almaMater;
    margin-bottom: 42px;
    @include maxWidth(710px) {
      margin-bottom: 24px;
    }
  }

  &__overview-section {
    position: sticky;
    top: 42px;
  }

  &__trials-section {
    grid-column: 2;
  }

  &__trials-header-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include maxWidth(710px) {
      flex-direction: column;
      margin-bottom: 26px;
      justify-content: flex-start;
    }
  }

  &__trial {
    margin-left: 24px;
    transform: rotate(180deg);
    min-width: 320px;

    &:last-child {
      margin-left: 0;
    }

    @include tabletWidth {
      transform: none;
      margin-left: 0;
      margin-right: 0;
      margin-top: 24px;
      width: 100% !important;
    }
  }

  &__ghost-card {
    border: dashed 2px $smokeDarkest;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    margin-left: 24px;
    min-width: 200px;
    transform: rotate(180deg);

    @include tabletWidth {
      transform: none;
      width: 100%;
      min-width: 100%;
      margin-left: 0;
      margin-top: 24px;
    }
  }
}
