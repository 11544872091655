@import "../../../../../App";

.prediction {
  @include bodySmall;

  border-bottom: 1px dashed $smokeDarkest;
  display: flex;
  justify-content: space-between;

  &__label {
    text-transform: capitalize;
  }
}
