$width-desktop: 1350px;
$width-tablet: 800px;
$width-mobile-large: 500px;
$width-mobile-small: 375px;

@mixin maxWidth($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin maxHeight($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

@mixin minWidth($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin desktopWidth {
  @include maxWidth($width-desktop) {
    @content;
  }
}

@mixin tabletWidth {
  @include maxWidth($width-tablet) {
    @content;
  }
}

@mixin mobileWidth-lg {
  @include maxWidth($width-mobile-large) {
    @content;
  }
}

@mixin mobileWidth-sm {
  @include maxWidth($width-mobile-small) {
    @content;
  }
}

