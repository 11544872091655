$gutterSize-desktop: 150px;
$gutterSize-tablet-lg: 100px;
$gutterSize-tablet: 80px;
$gutterSize-mobile: 20px;

@mixin gutters {
  margin-left: $gutterSize-desktop;
  margin-right: $gutterSize-desktop;

  @include desktopWidth {
    margin-left: $gutterSize-tablet-lg;
    margin-right: $gutterSize-tablet-lg;
  }

  @include tabletWidth {
    margin-left: $gutterSize-tablet;
    margin-right: $gutterSize-tablet;
  }

  @include mobileWidth-lg {
    margin-left: $gutterSize-mobile;
    margin-right: $gutterSize-mobile;
  }

  @include maxWidth(400px) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@mixin paddingGutters {
  padding-left: $gutterSize-desktop;
  padding-right: $gutterSize-desktop;

  @include desktopWidth {
    padding-left: $gutterSize-tablet-lg;
    padding-right: $gutterSize-tablet-lg;
  }

  @include tabletWidth {
    padding-left: $gutterSize-tablet;
    padding-right: $gutterSize-tablet;
  }
  @include mobileWidth-lg {
    padding-left: $gutterSize-mobile;
    padding-right: $gutterSize-mobile;
  }
  @include maxWidth(400px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@mixin leftGutters {
  padding-left: $gutterSize-desktop;
  margin-left: -$gutterSize-desktop;

  @include desktopWidth {
    padding-left: $gutterSize-tablet-lg;
    margin-left: -$gutterSize-tablet-lg;
  }

  @include tabletWidth {
    padding-left: $gutterSize-tablet;
    margin-left: -$gutterSize-tablet;
  }
  @include mobileWidth-lg {
    padding-left: $gutterSize-mobile;
    margin-left: -$gutterSize-mobile;
  }
  @include maxWidth(400px) {
    padding-left: 8px;
    margin-left: 8px;
  }
}

@mixin negativeMarginGutters {
  margin-left: -$gutterSize-desktop;
  margin-right: -$gutterSize-desktop;

  @include tabletWidth {
    margin-left: -$gutterSize-tablet;
    margin-right: -$gutterSize-tablet;
  }
  @include mobileWidth-lg {
    margin-left: -$gutterSize-mobile;
    margin-right: -$gutterSize-mobile;
  }
  @include maxWidth(400px) {
    margin-left: -8px;
    margin-right: -8px;
  }
}

@mixin negativeMarginAndPaddingGutters() {
  @include paddingGutters;
  @include negativeMarginGutters;
}

@mixin marginAndPaddingGutters() {
  @include paddingGutters;
  @include negativeMarginGutters;
}
