@import "../../App";

.home-page-task-controls {
  $selectBreak: 1310px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  flex-wrap: wrap;

  &__task-header {
    display: none;

    @include maxWidth($selectBreak) {
      @include label;
      color: $richNavy;
      margin-top: 32px;
      display: block;
      margin-bottom: 12px;

    }

    @include tabletWidth {
      margin-top: 16px;
    }


  }

  &__btn-area {
    display: flex;
    background-color: $smokeDarkest;
    gap: 4px;
    margin-left: -48px;
    margin-right: -48px;

    @include maxWidth($selectBreak) {
      display: none;
      margin-left: inherit;
      margin-right: inherit;
      border: 2px solid $azul;
      border-radius: 8px;
      flex-direction: column;
      align-items: flex-start;
      background-color: $white;
      gap: 0;
    }

    &-shown {
      display: flex;


    }
  }

  &__toggle-btn {
    display: none;
    transition: 0.25s;
    @include headline5;
    color: $richNavy;
    position: relative;


    @include maxWidth($selectBreak) {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      border: 2px solid $azul;
      padding: 8px 16px;
      margin-bottom: 12px;
    }

    &:after {
      background-image: url("../../resources/icons/chevron-down-azul.svg");
      background-repeat: no-repeat;
      content: " ";
      position: absolute;
      top: 16px;
      right: 12px;
      height: 16px;
      width: 16px;
      transition: 0.50s;
    }

    &-active {
      &:after {
        transform: rotate(180deg);
        top: 8px;
      }
    }

    &-active, &:hover {
      background-color: $azul;
      color: $white;

      svg path {
        fill: $white;
      }

      &:after {
        background-image: url("../../resources/icons/chevron-down-white.svg");
      }
    }

    &-icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  &__task-btn {
    @include headline6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    transition: 0.25s;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $smokeDark;
    color: $charcoalLightest;
    width: calc(20%);
    line-height: 1.2rem;

    svg path {
      fill: $charcoalLightest;
    }

    &-selected, &:hover {
      background-color: $white;
      color: $richNavy;

      svg path {
        fill: $azul;
      }
    }

    @include maxWidth($selectBreak) {
      justify-content: flex-start;
      border-radius: 4px;
      border: none;
      padding: 12px;

      width: 100%;
      background-color: $smokeDark;
      border-bottom: 1px solid $smokeDarkest;

      &-selected, &:hover {
        background-color: $white;
        color: $richNavy;

        svg path {
          fill: $azul;
        }
      }
    }


    &-icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      margin-right: 8px;
    }
  }
}
