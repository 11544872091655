@import "src/App";

.modal {
  align-items: center;
  background: rgba($almaMater, .4);
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  position: fixed;
  z-index: 100;

  &__content-container {
    background: $primaryGradient;
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
    padding: 4px;

    @include tabletWidth {
      margin-left: 64px;
      margin-right: 64px;
    }
    @include mobileWidth-lg {
      margin-left: 23px;
      margin-right: 23px;
    }

  }

  &__content {
    align-items: center;
    background-color: white;
    border: solid 4px transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 40px 40px 40px 40px;
    overflow-y: auto;
    max-height: 80vh;

    @include maxWidth(900px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1100000000;
      max-height: 100vh;


    }

    @include mobileWidth-lg {
      padding: 40px 24px 24px 24px;
    }

  }

  &__confirmation-content {
    align-items: center;
    background-color: white;
    border: solid 4px transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 80px 40px 40px 40px;

    @include maxWidth(900px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1100000000;
      max-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  &__top-icon {
    background: $secondaryGradient;
    border-radius: 50%;
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 107px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 107px;

    > * {
      margin: auto;
    }

    @include maxWidth(900px) {
      position: relative;
      justify-content: center;
      align-items: center;
      left: 0;
      transform: none;
      margin-bottom: 24px;
    }

    @include maxHeight(500px) {
      display: none;
    }
  }

  &__close-button {
    position: absolute;
    right: 20px;
    top: 20px;

    svg path {
      transition: fill .3s;
      fill: #4E4E4E;
    }

    &:hover svg path {
      fill: $purple
    }
  }
}
