@import "src/App";
.text-conversation-output {
    &__output-container {
        border: 1px solid $smokeDarker;
        height: 202px;
        overflow-y: scroll;
    }
    &__chat-container {
        display: flex;
        flex-direction: column;
        padding: 16px 32px;
        height: 200px;
        .chat-profile-icon {
            color: white;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px;      
        }
        .speech-bubble {
            padding: 15px;
            border-radius: 15px;    
            margin-bottom: 15px;
        }
    }
    &__chat-user-message {
        display: flex;
        justify-content: flex-end;
        padding-left: 10%;
        .user-icon {
            background-color: $azulDarkest;
            margin-left: 10px;
        }
        .speech-bubble {
            background-color: $azul;
            color: white;
        }
    }
    &__chat-assistant-message {
        display: flex;
        justify-content: flex-start;
        padding-right: 5%;
        .assistant-icon {
            background-color: $grayDark;
            margin-right: 10px;
        }
        .speech-bubble {
            background-color: $smokeDarker;
        }
    }

    &__input-submit-button-content {
        width: 80px;
    }

    &__input-container-text {
        min-height: 80px;
    }

    &__input-submit-row {
        display: flex;
        justify-content: flex-end;
    }

    &__spinner-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }    
    &__spinner {
        animation: spin 1s linear 0s infinite;
        border-bottom: solid 3px $purple;
        border-left: solid 3px transparent;
        border-radius: 50%;
        border-right: solid 3px $purple;
        border-top: solid 3px $purple;
        height: 25px;
        width: 25px;
      }    
}