@import "../../App";

.model-list-page {
  &__responsive-header {
    grid-area: header;


    &-search-container {
      display: none;
      @include desktopWidth {
        display: block;
        width: 100%;
        span {
          width: 100%;
        }
        margin-bottom: 12px;
      }
    }

    &-filter-container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      grid-template-columns: 3fr 1fr;
      align-items: center;

      .model-list-page__sort-container {
        align-self: flex-end;
      }

      .active-filters {
        width: 100%;

        @include desktopWidth {
          width: unset;
        }
      }

      @include desktopWidth {
        display: block;
      }

      &-placeholder {
        width: 32px;
        height: 32px;
        @include desktopWidth {
          display: none;

        }
      }

      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include mobileWidth-lg {
          flex-direction: column;
        }

        .model-list-page__sort-container {
          margin: 22px 0;


          @include desktopWidth {
          }

          @include mobileWidth-lg {
            width: 100%;
            margin-top: 8px;
          }
        }

      }

      &-btn {
        @include primaryButtonWithIconBefore("filter.svg");

        display: none;
        border-radius: 5px;
        background: $azul;
        margin-bottom: 22px;
        margin-top: 22px;
        @include desktopWidth {
          display: flex;
          align-items: center;

        }
        @include mobileWidth-lg {
          width: 100%;
          justify-content: center;
          margin-bottom: 8px;
        }
      }
    }
  }
}

