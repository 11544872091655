@import "src/App";

.intro-tutorial-plant-output {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;


  &__intro-text {
    @include labelBold();
    color: $azul;
    margin-bottom: 12px;
    text-align: center;

  }

  &__title {
    @include billboardSmall;
    color: $richNavy;
    margin-bottom: 50px;
  }

  .classification-output {
    width: unset;
    max-height: 500px;

    .top-prediction__probability {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__explanation {
    background-color: rgba($electricGreenLight, 0.2);
    padding: 16px 20px;
    display: flex;
    position: relative;
    max-width: 650px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 10px;
    width: 100%;

    &-arrow {
      position: absolute;
      top: -193px;
      left: 25px;
      height: 184px;
      transform: rotate(-10deg);

      @include maxWidth(700px) {
        height: 67px;
        top: -59px;
        left: 69px;
        transform: rotate(-45deg);
        display: none;

        path {
          stroke-width: 2px;
        }
      }
    }

    &-info {
      width: 20px;
      height: 20px;
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      margin-right: 12px;
      margin-top: 3px;
    }

    &-text {
      @include body;
      color: $charcoalLightest;

      @include mobileWidth-lg {
        @include bodySmall;
      }
    }
  }

  &__cta {

    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }
}
