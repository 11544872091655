@import "../../App";

@keyframes closeCard {
  0% {
    transform: translate(0, 0);
    opacity: 1;
    z-index: -1;
  }
  100% {
    transform: translate(2000px, 0);
    opacity: 0;
  }
}

.trial-output-wrapper {
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  transition: height 5s;


  &__closing {
    animation: closeCard 0.5s forwards;
  }

  &__no-input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;

    &-text {
      @include headline3;
      color: $charcoalLightest;
    }
  }

  &__title-box {
    background: $almaMater;
    border-radius: 5px 5px 0 0;
    display: flex;
    color: white;
    justify-content: space-between;
    padding: 24px;
    padding-top: 40px;
    min-height: 150px;
    position: relative;
    flex-direction: column
  }

  &__title-definition-list {
    margin-bottom: 0;
  }

  &__model-label,
  &__detail-label {
    @include labelSmall;
  }

  &__model-name {
    @include headline3;
    color: $white;
    text-decoration: underline;
    transition: color 0.3s;
    word-wrap: anywhere;
    width: 100%;

    @include safari {
      overflow-wrap: break-word;
    }
  }

  &__model-name:hover {
    color: $electricGreen;
  }

  &__delete-trial-button {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &-wrapper {
      position: absolute;
      top: 20px;
      right: 24px;
    }
  }

  &__delete-trial-button path {
    transition: fill 0.3s;
  }

  &__delete-trial-button:hover path {
    fill: $electricGreen;
  }

  &__content-box {
    background: white;
    border-radius: 0 0 5px 5px;
    padding: 20px 24px 24px 24px;

    @include mobileWidth-lg {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

  }

  &__model-details {
    align-items: baseline;
    display: flex;
    margin-bottom: 0;

    @include tabletWidth {
      flex-direction: column;
      justify-content: flex-start;
    }

    &-section {
      display: flex;

      @include tabletWidth {
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__detail-label {
    color: $charcoalLightest;
    line-height: 26px;
    margin-right: 8px;
  }

  &__model-tag {
    margin-right: 24px;
  }

  &__link {
    color: $charcoalLightest;
    margin-right: 6px;
    transition: color 0.3s;
  }

  &__link:hover {
    color: $purple;
  }

  &__loading {
    border: 1px solid $smokeDarker;
    padding: 24px 16px;
  }

  &__loading-header {
    @include labelSmall;
    color: $charcoalLightest;
    margin-bottom: 24px;
  }

  &__spinner-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__spinner {
    animation: spin 1s linear 0s infinite;
    border-bottom: solid 3px $purple;
    border-left: solid 3px transparent;
    border-radius: 50%;
    border-right: solid 3px $purple;
    border-top: solid 3px $purple;
    height: 50px;
    margin-bottom: 16px;
    width: 50px;
  }

  &__spinner-text {
    @include headline3;
    color: $charcoalLightest;
    margin-bottom: 16px;
    text-align: center;
  }
}
