@import "../../App";

.selected-model-card {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  padding: 32px 24px 16px;
  position: relative;
  width: 380px;

  &__name {
    @include headline3;
    color: $almaMater;
    display: inline-block;
    margin-bottom: 12px;
    overflow-wrap: anywhere;
  }

  &__tag-group {
    align-items: baseline;
    display: flex;
    margin-bottom: 12px;
  }

  &__tag-label {
    @include labelSmall;
    color: $charcoalLightest;
    display: inline-block;
    margin-right: 8px;
  }

  &__close {
    background: $grayDarkest;
    background-size: 200% 200%;
    border-radius: 5px;
    display: block;
    padding: 7px;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: background-position .3s;

    &:hover {
      background: $purple;
    }

    & svg {
      display: block;
      height: 10px;
      width: 10px;

      & path {
        fill: white;
      }
    }
  }
}
