@import "../../App";

.selected-models-banner {
  $breakpoint: 925px;

  bottom: 0;
  left: 0;
  right: 0;
  position: sticky;
  z-index: 2;
  box-shadow: 0px -4px 4px 0px #00000040;
  margin-top: auto;
  &__red-circle {
    border-radius: 150px;
    padding: 2px 8px;
    background-color: $electricGreen;
    @include body;
    color: $richNavy;
    font-weight: bold;
    margin-left: 8px;
  }

  @include maxWidth($breakpoint) {
    max-height: 35vh;
    overflow-y: auto;
  }

  &__toggle-col {
    align-self: center;

    @include maxWidth($breakpoint) {
      align-self: flex-start;
    }

    button {
      @include minWidth($breakpoint) {
        margin-top: 0;
      }
    }
  }

  &__controls {
    @include paddingGutters;
    background: $almaMater;
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    transition: box-shadow 0.3s;
    z-index: 1;
    align-items: flex-start;

    &-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      > * {
        margin-left: 16px;
      }

      @include maxWidth($breakpoint) {
        flex-direction: column;
        align-items: flex-start;
        > * {
          margin-top: 16px;

          &:nth-child(2) {
            margin-top: 8px;
          }
        }
      }
    }

    &--open {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__toggle {
    align-items: center;
    display: flex;
    margin-top: 24px;
  }

  &__toggle-icon {
    margin-right: 16px;
    transition: transform 0.3s;

    &--open {
      transform: rotateX(180deg);
    }

    & path {
      transition: fill 0.3s;
    }
  }

  &__hide-text {
    @include linkSmall;
    text-decoration: none;
    color: $white;
    margin-right: 64px;
    transition: color 0.3s;

    &:hover {
      color: $electricGreen;
    }
  }

  &__toggle-text {
    @include headline3;
    color: white;
    transition: color 0.3s;
    flex-shrink: 0;
  }

  &__toggle:hover &__toggle-icon path {
    fill: $electricGreen;
  }

  &__toggle:hover &__toggle-text {
    color: $electricGreen;
  }

  &__remove {
    @include linkSmall;
    color: white;
    transition: color 0.3s;

    &:hover {
      color: $electricGreen;
    }
  }

  &__run {
    margin-left: auto;

    @include maxWidth($breakpoint) {
      margin-left: 0;
      align-self: flex-end;
    }
  }

  &__list {
    @include paddingGutters;
    background: $almaMater;
    display: flex;
    overflow-x: hidden;
    max-height: 0;
    transition:
      max-height 0.3s,
      padding-top 0.3s,
      padding-bottom 0.3s;

    @include mobileWidth-lg {
      flex-direction: column;
      align-items: center;
    }

    &--open {
      max-height: 500px;
      overflow-x: auto;
      padding-top: 32px;
      padding-bottom: 32px;
    }

    & > * {
      margin-right: 16px;

      @include mobileWidth-lg {
        margin-top: 16px;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
