@import "../../App";

.home-page-section {
  margin-top: 160px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;


  &__title {
    @include billboardSmall;
    color: $richNavy;
    text-align: center;
  }

  &__subtitle {
    margin-top: 16px;
    @include bodyLarge;
    color: $charcoalLightest;
    text-align: center;
  }
}
