@import "../../App";

.footer {
  background: $richNavy;
  position: relative;
  z-index: 1;
  padding: 12px 0;

  @include mobileWidth-lg {
    padding: 24px 0;
  }


  &__logos-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;

    @include mobileWidth-lg {
      flex-direction: column;
    }
  }

  &__logo {
    height: 100%;
    transition: opacity .3s;
    padding: 20px 28px;

    &:hover {
      opacity: .8;
    }
  }

  &__buffalo-logo {
    height: 70px;
    transition: opacity .3s;
    padding: 20px 28px;

    &:hover {
      opacity: .8;
    }
  }
}
