@import "src/App";

.uppy-Root {
  font-family: $sans-serif;
}

.uppy-Dashboard-browse {
  color: $azul;
}

.uppy-StatusBar.is-waiting
.uppy-StatusBar-actionBtn--upload {
  background-color: $azul;

  &:hover {
    background-color: $purple;
  }
}

.upload-inputs {
  width: 100%;

  &__help-text {
    @include body;
    color: $charcoalLightest;
    margin-bottom: 24px;
  }

}

