@import "../../../App";

.image-enhancement-summary {
  display: flex;
  border-top: 1px solid $smokeDarker;
  padding-top: 24px;
  width: 100%;
  justify-content: center;

  @include desktopWidth {
    flex-direction: column;
    padding-top: 0;

  }
  @include tabletWidth {
  }

  &__input {
    margin-right: 36px;
  }

  &__title {
    @include labelSmall;
    color: $charcoalLightest;

    margin-bottom: 10px;
  }


  &__summary {

    @include desktopWidth {
      margin-top: 0px;
    }

    .image-enhancement-image__output {

      &-canvas {
        padding-top: 0;
      }
    }

    .image-enhancement-image__wrapper {
      width: 350px;

      @include maxWidth(1600px) {
        width: 300px;
      }
      @include maxWidth(1450px) {
        width: 250px;
      }

      @include maxWidth(800px) {
        width: 450px;
      }
      @include maxWidth(750px) {
        width: 400px;
      }
      @include maxWidth(650px) {
        width: 300px;
      }
      @include maxWidth(600px) {
        width: 200px;
      }
    }

    &__header {


      &-heading {
        color: $charcoalLightest;
        @include labelSmall;
      }

      &-subheading {
        @include body;
        color: $charcoalLightest;
      }
    }

    &__back-link {
      @include secondaryButtonWithIconBefore("arrow-right.svg");
      max-width: 270px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
