@import "../../../../../App";

.semantic-segmentation {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 32px;
  @include maxWidth(1000px) {
    margin-left: 0;
  }

  &__header {
    width: 100%;
    border-bottom: 1px solid $smokeDarkest;
    padding-bottom: 16px;
    margin-bottom: 24px;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include maxWidth(475px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        h3 {
          margin-top: 8px;
        }
      }
    }

    &-heading {
      @include headline3;
    }

    &-subheading {
      color: $charcoalLightest;
      @include body;
    }
  }

  &__top-row {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    max-height: 100vh;

    @include tabletWidth {
      grid-template-columns: 1fr;
      max-height: 100%;
    }
  }

  &__bottom-row {
    margin-top: 16px;

    &-btn {
      @include secondaryButtonWithIconBefore("arrow-right.svg");
      max-width: 270px;
    }

    @include tabletWidth {
      margin-top: 32px;
    }
  }
}
