@import "../../../App";

.process-failed {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 16px;

  padding: 32px;
  width: 0;

  &__subtitle {
    @include body;
    border-bottom: 1px solid $smokeDarkest;
    margin-bottom: 24px;
    padding-bottom: 16px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(30, 99, 242, 0.03);
    border-radius: 5px;
    min-height: 400px;

    svg {
      width: 64px;
      height: 64px;
    }

    &-heading {
      margin-top: 16px;
      color: $charcoalLightest;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    &-link {
      color: $azul;
      font-size: 20px;
      line-height: 28px;
    }
  }


}
