@import "src/App";

.back-to-top-button {
  @include button;
  @include buttonWithIconAfter("arrow-right-white.svg");

  background-color: $purple;
  border-color: $purple;
  color: $white;
  position: sticky;
  text-decoration: none;
  z-index: 10;
  bottom: 12px;
  left: 100%;
  margin-bottom: 12px;
  margin-right: 12px;
  align-self: flex-end;
  box-shadow: 0px 0px 12px 2px #00000040;

  &:hover {
    background-position: bottom center;
  }


  &:after {
    transform: translateY(0px) rotate(-90deg);
  }

  &:not(:disabled) {
    &:hover:after {
      transform: translateY(-2px) rotate(-90deg);
    }
  }

  &__hidden {
    visibility: hidden;
  }
}
