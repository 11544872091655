@import "../../App";


@keyframes slideUp {
  0% {
    max-height: unset;
  }
  1% {
    transform: scaleY(1);
    display: block;
  }
  to {
    transform: scaleY(0);
    height: 0;
    display: none;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
    display: none;
  }
  99% {
    max-height: 100vh;
    display: block;
  }
  to {
    max-height: unset
  }
}

@keyframes expandOut {
  0% {
    max-height: unset;
  }
  1% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}

@keyframes collapseIn {
  0% {
    max-height: unset;
  }
  1% {
    max-height: 100%;
  }
  100% {
    max-height: 0%;
  }
}

.header {
  $mobileBreakpoint: 1025px;
  @include paddingGutters();
  background: white;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100px;
  margin-top: -100px;
  box-shadow: 0px 4px 4px 0px #00000040;


  &__splash {
    background: transparent;
    box-shadow: none;

    .header__responsive-menu-icon {

      span {
        background-color: $white;
      }
    }
  }

  &__scrolled {
    box-shadow: 0px 4px 4px 0px #00000040;

    &-splash {
      background-color: $richNavy;
    }
  }

  &__placeholder {
    width: 100%;
    height: 100px;
  }

  &__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  &__menu {
    display: flex;
    align-items: center;
    @include maxWidth($mobileBreakpoint) {
      display: none;
    }

    .header__library-link-container {
      margin-left: 24px;
    }
  }

  &__responsive-menu-icon {
    display: none;

    @include maxWidth($mobileBreakpoint) {
      display: block;
      align-self: flex-end;
      width: 25px;
      height: 10px;
      padding: 12px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $azul;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }


      span:nth-child(1) {
        top: 0;
      }
      span:nth-child(2), span:nth-child(3) {
        top: 7px;
      }
      span:nth-child(4) {
        top: 15px;
      }
      &-open {
        span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }


  }


  &__beta {
    @include fitToContent;
    @include label;
    background: $secondaryGradient;
    border-radius: 0 5px;
    display: block;
    color: white;
    margin-bottom: 6px;
    padding: 0 4px;
    text-transform: uppercase;
  }

  &__logotype {
    background: $primaryGradient;
    background-size: 200% 200%;
    color: $azul;
    font-size: 35px;
    font-weight: 300;
    line-height: 1;
    text-decoration: none;
    transition: .3s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      background-position: bottom center;
      opacity: .8;
    }

    &__modelscope {
      font-weight: 700;
    }
  }

  &__splash &__logotype {
    background: unset;
    color: white;
    transition: color 0.3s;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;

    &:hover {
      color: $electricGreen;
    }
  }

  &__library-link-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
      .header__library-link {
        color: $purple;
      }

      .header__arrow-icon {
        transform: translateX(6px);

        path {
          fill: $purple;
        }
      }
    }

    &-expandable {
      display: grid;
      grid-template-columns: 8fr 1fr;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $electricGreen;
    }

    &-expand {
      padding-left: 26px;
      padding-right: 46px;

      &:hover {
        background-color: $azulDark;

        svg path {
          fill: $electricGreen;
        }
      }

      svg {
        width: 12px;
        transition: transform 0.5s;

        path {
          fill: $white;
        }
      }

      &-rotated {
        background-color: $azulDark;

        svg {
          transform: rotate(180deg);
        }

      }

    }

  }

  &__submenu-item {
    background: $white;
    border: none;

  }

  &__library-link {
    @include headline6;
    color: $azul;
    margin-right: 6px;
    text-decoration: none;
    transition: color 0.3s;
  }

  svg path {
    fill: $azul;

    @include maxWidth($mobileBreakpoint) {
      fill: $white;
    }

  }

  &__splash &__library-link-container {

    .header__library-link {
      color: white;
    }

    .header__arrow-icon path {
      fill: white;
    }

    &:hover {
      .header__library-link {
        color: $electricGreen;
      }

      .header__arrow-icon path {
        fill: $electricGreen;
      }
    }
  }

  &__arrow-icon {
    width: 13px;
    height: 12px;
    transition: transform 0.3s;
  }

  &__responsive-menu {
    &-header {
      background-color: $azul;
      cursor: pointer;

      &:hover {
        background-color: $azulDark;


      }
    }
  }

  &__shown-menu {
    @include negativeMarginAndPaddingGutters();
    @include maxWidth($mobileBreakpoint) {
      margin-left: 0;
    }
    background: $white;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
    margin-bottom: 16px;
    width: 100vw;

    .header__row {
      padding-bottom: 11px;
    }


  }


  &__responsive-menu {
    display: none;
    background-color: $azulDarkest;
    width: 100vw;
    position: static;
    bottom: 0;
    left: 0;
    @include negativeMarginGutters();

    @include maxWidth($mobileBreakpoint) {
      margin-left: -100px;

      @include tabletWidth {
        margin-left: -$gutterSize-tablet;
        margin-right: -$gutterSize-tablet;
      }
      @include mobileWidth-lg {
        margin-left: -$gutterSize-mobile;
        margin-right: -$gutterSize-mobile;
      }
      @include maxWidth(400px) {
        margin-left: -8px;
        margin-right: -8px;
      }
    }


    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .header {
      &__library-link-container {
        padding-top: 16px;
        padding-bottom: 16px;

        svg path {
          fill: $white;
        }
      }

      &__library-link {
        color: $white;
      }
    }

    &-children {
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
      transition-delay: 0s;

      &-open {
        //max-height: 350px;

      }

      &-closing {
        max-height: 0;

      }
    }

    &-open {
      display: block;
    }

    &-section {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $electricGreen;

      &:last-child {
        border-bottom: none;
      }

      .header__responsive-menu-item {
        border-bottom: none;
      }
    }

    &-item {
      border-bottom: 1px solid $electricGreen;
      padding: 16px 20px 16px 80px;

      @include mobileWidth-lg {
        padding-left: 20px;
      }
      display: flex;
      align-items: center;

      &:hover {
        .header__library-link {
          color: $electricGreen;
        }

        .header__arrow-icon {
          transform: translateX(6px);

          path {
            fill: $electricGreen;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }


      &-expandable {
        justify-content: space-between;
      }
    }
  }

  &__responsive-submenu-item {
    background-color: $white;
    display: flex;
    align-items: center;
    border-bottom: none;

    &:last-child {
      padding-bottom: 32px;
    }

    &:hover {
      .header__library-link {
        color: $purple;
      }

      svg path, .header__arrow-icon path {
        fill: $purple;
      }
    }

    .header__library-link {
      color: $azul;
      display: flex;
      align-items: center;

      &:hover {
        color: $purple;

        svg path {
          fill: $purple;
        }

        .header__arrow-icon path {
          fill: $purple;
        }
      }
    }

    svg {
      path {
        fill: $azul;
      }
    }

  }

  &__submenu-icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &__submenu-header {
    background-color: $white;
    @include paddingGutters;
    @include headline4;
    color: $azulDarkest;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__compare-models {
    position: relative;

    &-title {
      @include headline5;
      color: $azulDarkest;

    }

    &-btn {
      border: 2px solid $white;
      background-color: $azul;
      color: $white;
      border-radius: 30px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      @include headline6;
      transition: 0.25s;

      &:hover {
        border-color: $electricGreen;
        color: $electricGreen;
        background-color: $azulDark;
      }

      &-active {
        background-color: $white;
        color: $azul;
        border-color: $azul;

        &:hover {
          background-color: $white;
          color: $purple;
        }
      }

      &-icon {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        transition: 0.25s;

        path {
          fill: white !important;

        }

        &-active {
          path {
            fill: $azul !important;

          }

          transform: rotate(180deg);
        }
      }
    }

    &-container {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      width: clamp(400px, 30vw, 600px);
      background-color: $white;
      border: 2px solid $skyBlue;
      border-radius: 10px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-next {
      @include primaryButtonWithIcon(center, "arrow-right-white.svg");
      @include headline6;
      margin-top: 24px;
      padding: 12px 24px;
      align-self: flex-end;
      text-decoration: none;
    }

    &-tasks {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
    }

    &-task {
      padding: 8px 16px;
      border-radius: 20px;
      border: 2px solid $azul;
      color: $azul;
      display: flex;
      align-items: center;
      margin-right: 12px;
      margin-top: 12px;
      transition: 0.25s;
      @include headline6;

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        // Styling for .svg Task icons to make sure they are $white and $azul
        path {
          fill: $azul !important;
        }
        rect {
          stroke: $azul !important;
        }
        circle {
          stroke: $azul !important;
          fill: $azul !important;
        }
        polyline {
          stroke: $azul !important;
        } 
      }

      &:hover, &-selected {
        background-color: $azul;
        color: $white;
        // Styling for .svg Task icons to make sure they are $white and $azul
        // Get more icons at https://www.svgrepo.com/
        path {
          fill: $white !important;
        }
        line {
          stroke: $azul !important;
        }
        .a {
          // Currently not used?
          stroke: $azul !important;
        }
        rect {
          stroke: $white !important;
        }
        circle {
          stroke: $white !important;
          fill: $white !important;
        }
        polyline {
          stroke: $white !important;
        } 
      }
    }


  }

  &__library-link-bar {
    width: 100%;
    height: 18px;
    background-color: transparent;
    position: absolute;
    bottom: -21px;

    @include maxWidth($mobileBreakpoint) {
      display: none;
    }
  }

  &__link-active {
    .header__library-link-bar {
      background-color: $purple;
    }
  }
}

.header__splash.header__shown-menu {
  background: $richNavy;

  .header__logotype {
    color: $white;
    -webkit-text-fill-color: $white;
  }
}
