@import "../../../../../App";

.image-enhancement {
  display: flex;
  flex-direction: column;
  width: 100%;


  &__image {
    width: 100%;
    height: auto;
  }

  &__input {
    box-shadow: 0px 0px 15px -2px #00000040;
    border-radius: 5px;
    width: 33%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: $white;


    @include maxWidth(1550px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__back-button {
    @include secondaryButtonWithIconBefore("arrow-right.svg");

    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-size: 16px;

    @include maxWidth(1550px) {
      max-width: 300px;
    }
  }

  .input-preview {
    height: 320px;
  }
}
