@import "../../App";

.experiment-detail-header {
  @include paddingGutters;
  background: url("../../resources/img/wavy-splash.jpg") no-repeat;
  background-size: cover;
  padding-top: 72px;
  padding-bottom: 64px;

  &__title {
    @include headline1;
    color: white;
    margin-bottom: 0;

    @include mobileWidth-lg {
      font-size: 48px;
      line-height: 42px;
    }
  }

  &__subtitle {
    @include bodyLarge;
    color: $white;
  }
}
