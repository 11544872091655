@import "src/App";

.model-list-no-results {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $smoke;
  padding: 32px;
  border-radius: 10px;

  &__icon {
    width: 78px;
    height: 78px;
    margin-bottom: 12px;

    @include mobileWidth-sm {
      width: 64px;
      height: 64px;
    }

    path {
      fill: $grayDark;
    }
  }

  &__heading, &__subheading {
    text-align: center;
    color: $charcoalLightest;
  }

  &__heading {
    @include headline3;
  }

  &__subheading {
    @include body;
    margin-bottom: 12px;
  }

}
