@keyframes slideInDown  {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}
@keyframes slideOutUp {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}


.model-list-page {
  &__filter-content {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 25;
    top: 0;
    padding: 20px;
    padding-top:0;
    animation: 0.5s slideInDown forwards;

    &-closing {
      animation: 0.5s slideOutUp forwards;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $smokeDark;
      font-weight: 800;
      font-size: 16px;
      padding-bottom: 30px;
      margin-bottom: 16px;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding-top: 20px;
      &-title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: $charcoalLightest;

        svg {
          margin-right: 8px;

          path {
            fill: $charcoalLightest;
          }
        }
      }

      &-close {
        transition: 0.5s;
        &:hover {
          path {
            fill: $grayDarkest;
          }
        }
        svg {
          width: 20px;
          height: 20px;

          path {
            fill: $charcoalLightest;

          }
        }
      }
    }
  }
}
