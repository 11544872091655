@import "../../../App.scss";

.multi-input-preview {
    &__multi-input-grid-display {
        display: flex;
        flex-direction: column;

        max-width: 320px;
    }
    // &__multi-input-grid-primary-row {
    // }
    &__multi-input-grid-secondary-row {
        display: flex;
        flex-wrap: wrap;
    }
    &__multi-input-grid-item {
        display: flex;
        flex-basis: 25%;
    }     
}
