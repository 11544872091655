.model-description {
  background-color: $white;
  width: 100%;
  padding-top: 84px;
  padding-bottom: 130px;
  @include paddingGutters;
  padding-left: $gutterSize-desktop + 160px;
  @include desktopWidth {
    padding-top: 62px;
    padding-bottom: 54px;
  }
  @include mobileWidth-lg {
    padding-top: 47px;
    padding-bottom: 93px;
  }

  &__divider {
    margin-left: -160px;
    margin-bottom: 20px;
    width: 483px;
    height: 3px;
    background: linear-gradient(270.01deg, #6430E1 0.42%, #0072F7 98.76%);

    @include desktopWidth {
      margin-left: 0;
      width: 324px;
    }
    @include mobileWidth-lg {
      margin-bottom: 22px;
    }
  }

  &__heading {
    color: $azulDarkest;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  }

  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    @include maxWidth(750px) {
      flex-direction: column;
    }

    &-section {
      margin-right: 70px;
      margin-bottom: 32px;
      flex-basis: 60%;

      p {
        @include body;
        color: $charcoalLightest;

        @include tabletWidth {
          @include bodySmall;
          color: $charcoalLightest;

        }
      }
    }

  }

  &__link-section {

    &-wrapper {
      display: flex;
      margin: 24px 0;
      align-items: flex-start;

      &:first-child {
        margin-top: 0;
      }
    }

    &-link {
      color: $azulDark;
      @include linkSmall;
      overflow-wrap: anywhere;

      &:hover {
        color: $purple;
      }

      &-padded {
        padding-left: 25px;
        margin-top: -20px;
      }
    }

    &-icon {
      margin-right: 12px;

      svg {
        path {
          fill: $purple;
        }
      }

    }
  }

  &__content-wrapper-links-robot {
    path {
      fill: $white !important;
    }

    ellipse {
      fill: $purple !important;
    }
  }
}
