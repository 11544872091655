@import "../../App";

.sort-button {
  @include fitToContent;
  position: relative;

  &__button {
    @include headline6;
    background: $smokeDark;
    border: none;
    border-radius: 5px;
    color: $charcoalLightest;
    padding: 16px;
    transition: background-color .3s;

    &:hover {
      background: $smokeDarker;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__option-box {
    background: $smokeDark;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $charcoalLightest;
    margin-top: 4px;
    min-width: 160px;
    padding-bottom: 6px;
    padding-top: 2px;
    position: absolute;
    text-align: center;
    right: 0px;
    z-index: 10;
    @include mobileWidth-lg {
      width: 100%;
    }
  }

  &__option {
    @include bodySmall;
    border: none;
    border-radius: 4px;
    background: none;
    cursor: pointer;
    display: block;
    margin: 6px;
    padding: 4px;
  }

  &__option:hover {
    background: $smokeDarker;
  }

  &__radio {
    display: none;
  }

  &__radio:checked + &__option {
    background: $smokeDarkest;
  }
}
