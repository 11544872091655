@import "src/App";

.canvas-input {
    &__help-text {
        font-size: 18px;
        font-weight: normal;
        line-height: 24px;
        color: $charcoalLightest;
        margin-top: 20px;
        margin-bottom: 20px;        
    }

    &__canvas-container {
        position: relative;
        top: 0;
        left: 0;
    }

    &__canvas-background {
        position: relative;
        top: 0;
        left: 0;
    }

    &__canvas-element {
        position: absolute;
        top: 0px;
        left: 0px;  
    }
}