@import "src/App";

.output-duration {
  background-color: $smokeDark;
  border-radius: 20px;
  padding: 4px 12px;
  display: flex;
  align-items: center;

  &__icon {
    width: 14px;
    height: 14px;
    min-height: 14px;
    max-height: 14px;
    min-width: 14px;
    max-width: 14px;
    margin-right: 4px;

    path {
      fill: $charcoalLightest;
    }
  }

  &__title {

  }

  &__label {
    @include labelSmall;
    color: $charcoalLightest;
  }

  &__seconds {
    text-transform: none;
  }
}
