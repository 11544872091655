@import "src/App";

.url-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    @include body;
  }

  &__url {
    background: $white;
    margin-top: 24px;
    padding: 20px;
    width: 100%;

    &::placeholder {
      opacity: .9;
    }

    &-error {
      border-color: $darkRed;

      &:focus {
        border-color: $pink;
      }
    }
  }

  &__error-text {
    @include bodySmall;
    color: $darkRed;
    margin-top: 16px;
  }

  &__add-btn {
    background-color: $smokeDarker;
    border-radius: 5px;
    @include headline6;
    color: $charcoalLightest;
    float: left;
    margin-top: 16px;
    width: 170px;
    padding: 16px 8px;
    transition: 0.25s;

    &:hover {
      background-color: $smokeDarkest;
    }

    &-icon {
      path {
        fill: $charcoalLightest;
      }
    }
  }
}
