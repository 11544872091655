@import "src/App";

.table-no-rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: $smoke;
  border-radius: 10px;

  &__main-heading {
    @include headline6;
    text-align: center;
    color: $charcoalLightest;
  }

  &__heading {
    @include bodySmall;
    text-align: center;
    color: $charcoalLightest;
  }
}
