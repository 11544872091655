@import "../../App";

.model-header {
  @include paddingGutters;
  background-image: url('../../resources/img/cube-splash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 72px;
  padding-bottom: 72px;
  position: relative;

  &:before {
    content: '';
    background-image: $secondaryGradient;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    mix-blend-mode: overlay;
  }

  &__title {
    @include headline1;
    color: $white;
    margin-bottom: 16px;
  }

  &__description {
    @include bodyLarge;
    color: $white;
  }
}
