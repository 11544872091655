@import "src/App";

.home-page {
  width: 100%;
  height: 100%;

  .home-page-tasks {
    @include gutters;
  }
}
