h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.125rem;
}

h3 {
  font-size: 0.875rem;
}

h4 {
  font-size: 0.75rem;
}

h5 {
  font-size: 1.5rem;
}

p {
  font-size: 0.875rem;
}

.PrimaryOrange {
  background: #e84a27;
}

.DarkBlue {
  background: #1a263a;
}

.LightGray {
  background: #e8e9eb;
}

.CenterBlock {
  margin-left: auto;
  margin-right: auto;
}
