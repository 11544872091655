@import "../../../App";

.quick-output {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__learn-more-link {
    @include linkLarge;
    margin-right: 16px;
    color: $azul;

    &:hover {
      color: $purple;
    }
  }

  &__title {
    align-self: flex-end;
  }

  &__share-button {
    @include secondaryButtonWithIconAfter("share.svg");

    &:after {
      height: 22px;
      width: 22px;
      vertical-align: text-bottom;
    }
  }

  &__content {
    display: flex;
    @include maxWidth(1000px) {
      flex-direction: column;
      > * {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 42px;

    @include maxWidth(900px) {
      flex-direction: column-reverse;
    }
  }

  &__compare-button {
    @include primaryButton();
    @include buttonWithIconAfter("../../resources/icons/arrow-right-white.svg");
    text-decoration: none;
    @include maxWidth(900px) {
      margin-bottom: 24px;
    }
  }
}
