.semantic-segmentation-image {
  position: relative;
  width: 100%;
  height: 100%;

  &__image {
    width: 100%;
  }

  &__overlay {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__label {
    padding: 4px 12px;
    z-index: 20;
    position: absolute;
  }
}
