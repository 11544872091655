* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:before,
*:after {
  box-sizing: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 1rem;
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

body {
  background-color: $smoke;
}


