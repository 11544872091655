@import "../../abstracts/type";

$duration: 0.5s;

%baseIcon {
  margin-left: 8px;
  height: 15px;
  transition: $duration;
  width: 16px;
}

@mixin button {
  @include headline5;
  border-radius: 26px;
  max-height: 48px;
  padding: 16px 24px;
  transition: $duration;
  text-align: center;

  @include mobileWidth-lg {
    font-size: 16px;
    line-height: 24px;
  }
  @include mobileWidth-sm {
    line-height: 16px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin buttonWithIconAfter($icon) {
  align-items: center;
  display: flex;

  &:after {
    background-image: url("../../resources/icons/" + $icon);
    background-repeat: no-repeat;
    content: " ";
    display: inline-block;
    height: 15px;
    margin-left: 8px;
    width: 16px;
    min-height: 15px;
    max-height: 15px;
    max-width: 16px;
    min-width: 16px;
    transform: translateX(0px);
    transition: $duration;
  }

  &:not(:disabled) {
    &:hover:after {
      transform: translateX(6px);
    }
  }
}

@mixin buttonWithIconBefore($icon) {
  align-items: center;
  display: flex;

  &:before {
    background-image: url("../../resources/icons/" + $icon);
    background-repeat: no-repeat;
    content: " ";
    display: inline-block;
    height: 15px;
    margin-right: 8px;
    width: 16px;
    transition: $duration;
  }

  &:not(:disabled) {
    &:hover:before {
      transform: translateX(-6px);
    }
  }
}

@mixin rotatedButtonWithIconBefore($icon) {
  @include buttonWithIconBefore($icon);

  &:before {
    transform: rotate(180deg);
  }

  &:not(:disabled) {
    &:hover:before {
      transform: rotate(180deg) translateX(6px);
    }
  }
}

@mixin primaryButton {
  @include button;

  background: $primaryGradient;
  background-size: 200% 200%;
  border-style: hidden;
  color: $white;

  &:not(:disabled) {
    &:hover {
      background-position: bottom center;
    }
  }
}

@mixin primaryButtonWithIcon($position, $icon: "arrow-right.svg") {
  @include primaryButton;
  @include buttonWithIconAfter($icon);

  &:#{$position} {
    @content;

    path {
      fill: #fff;
    }
  }

}

@mixin primaryButtonWithIconBefore($icon) {
  @include primaryButton;
  @include buttonWithIconBefore($icon);

  &:before {
    @content
  }
}

@mixin secondaryButton($backgroundPosition) {
  @include button;

  background: $primaryGradient;
  background-size: 200% 200%;
  border-style: hidden;
  color: $azul;
  position: relative;
  text-decoration: none;
  z-index: 0;

  &:hover {
    background-position: bottom center;
    color: $purple;
  }

  &:#{$backgroundPosition} {
    background-color: $white;
    border-radius: 26px;
    content: " ";
    position: absolute;
    bottom: 2px;
    left: 2px;
    right: 2px;
    top: 2px;
    z-index: -1;
  }
}

@mixin secondaryButtonWithIconAfter($icon) {
  @include secondaryButton('before');
  @include buttonWithIconAfter($icon);
}

@mixin secondaryButtonWithIconBefore($icon) {
  @include secondaryButton('after');
  @include rotatedButtonWithIconBefore($icon);
}

.primary-button,
.secondary-button {
  @include fitToContent;
  @include headline5;
  text-decoration-line: none;
  border-radius: 26px;
  display: block;
}

.primary-button {
  background: $primaryGradient;
  background-size: 200% 200%;
  padding: 10px 24px;
  transition: $duration;

  &__text {
    color: $white;
    text-decoration: none;
  }

  &__icon {
    @extend %baseIcon;
  }

  &__icon path {
    fill: white;
  }
}

.secondary-button {
  background: $primaryGradient;
  background-size: 200% 200%;
  padding: 2px;

  &__fill {
    background: $white;
    border-radius: inherit;
    display: block;
    padding: 8px 22px;
  }

  &__text {
    color: $azul;
    text-decoration: none;
    transition: color $duration;
  }

  &__icon {
    @extend %baseIcon;

    stop {
      transition: stop-color $duration;
    }
  }
}

.primary-button:hover {
  background-position: bottom center;
}

.secondary-button:hover {
  background-position: bottom center;
  cursor: pointer; // This might be removed once we have worked out the button issues here

  .secondary-button__icon {
    .top-stop {
      stop-color: $skyBlue;
    }

    .bottom-stop {
      stop-color: $purple;
    }
  }

  .secondary-button__text {
    color: $purple;
  }
}

.primary-button:hover .primary-button__icon,
.secondary-button:hover .secondary-button__icon {
  transform: translateX(6px);
}

.small-button {
  @include headline6;

  &__icon {
    width: 13px;
    height: 12px;
  }
}

