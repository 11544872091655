@import "../../App";

.prediction-expander {
  display: flex;
  flex-direction: column;

  &__predictions {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    max-height: 567px;
    overflow-y: auto;
    padding: 0 16px;

    & > * {
      margin-bottom: 16px;
    }
  }

  &__prediction-overflow {
    display: flex;
    flex-direction: column;
    max-height: 180px;
    transition: all 500ms;

    &--collapsed {
      max-height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    & > * {
      margin-bottom: 16px;
    }
  }

  &__expand {
    @include linkSmall;
    align-items: center;
    align-self: flex-end;
    color: $azul;
    display: flex;
    margin-top: 16px;
    transition: color 0.3s;

    &:hover {
      color: $purple;
    }

    &:after {
      content: "";
      background-image: url("../../resources/icons/chevron-down-azul.svg");
      display: inline-block;
      height: 6px;
      margin-left: 8px;
      transform: rotate(-180deg);
      transition: all 500ms;
      width: 12px;
    }

    &--collapsed {
      &:after {
        transform: rotate(0deg);
        //transition: all 300ms;
      }
    }
  }

}
