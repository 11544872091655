@import "../../App";

.model-tag {
  @include bodySmall;
  @include fitToContent;
  background: rgba($charcoalLightest, 0.05);
  border-radius: 20px;
  border: none;
  color: $charcoalLightest;
  margin-right: 0;
  padding: 2px 8px;

  &.-task {
    background: rgba($azul, 0.05);
    color: $azul;
  }

  &.-framework {
    background: rgba($purple, 0.05);
    color: $purple;
  }

  &.-machine {
    background: rgba($altgeld, 0.05);
    color: $altgeld;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 2px;

    &-machine {
      path {
        fill: $altgeld;
      }
    }
  }
}
