@import "../../../../../App.scss";

.object-detection {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 32px;

  @include maxWidth(1000px) {
    margin-left: 0;
    margin-right: 0;
  }

  @include mobileWidth-lg {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &__header {
    width: 100%;
    border-bottom: 1px solid $smokeDarkest;
    padding-bottom: 16px;
    margin-bottom: 24px;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include maxWidth(575px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        h3 {
          margin-top: 8px;
        }
      }
    }

    @include mobileWidth-lg {
      padding-left: 16px;
    }

    &-heading {
      @include headline3;
    }

    &-subheading {
      color: $charcoalLightest;
      @include body;
    }
  }

  &__top-row {
    display: flex;
    gap: 24px;

    @include maxWidth(1500px) {
      flex-direction: column;
    }
  }

  &__bottom-row {
    margin-top: 54px;

    &-btn {
      @include secondaryButtonWithIconBefore("arrow-right.svg");
      max-width: 270px;
    }

    @include mobileWidth-lg {
      display: flex;
      justify-content: center;
    }
  }
}
