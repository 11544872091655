@import "../../../App";

.classification-summary {
  border: 1px solid $smokeDarker;
  padding: 12px 8px;

  @include mobileWidth-lg {
    padding: 16px 8px;
  }

  &__header {
    @include headline5;
    color: $almaMater;

    &-row {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__top-result {
    @include headline4;
    background: rgba($azul, 0.03);
    display: flex;
    color: $charcoalLightest;
    justify-content: space-between;
    padding: 8px 16px;
    text-transform: capitalize;
  }
}
