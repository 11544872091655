@import "src/App";

.sample-inputs {
  &__title {
    @include body;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;

    @include tabletWidth {
      flex-direction: column;
      align-items: center; // TODO: Update this so that text/audio aren't centered

      .sample-inputs {
        &__input-image,
        &__input-text,
        &__input-audio {
          &:not(:first-child) {
            margin-left: 0px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  &__input-image {
    line-height: 0;
    width: 100%;
    max-width: 240px;

    & > img {
      border: 6px solid transparent;
      width: 100%;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      & > img {
        border-color: $azul;
        box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &--unselected {
      opacity: 0.5;
    }

    &--large {
      max-width: 100% !important;
    }
  }

  &__input-text {
    border: 1px solid $gray;
    flex: 1 1;
    padding: 4px 8px;
    text-align: start;

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      border-color: $azul;
      box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--unselected {
      opacity: 0.5;
    }
  }

  &__input-document {
    display: flex;
    align-items: end;
    justify-content: left;
    flex-grow: 1;
    gap: 10px;
    border: 1px solid $gray;

    padding: 7px 8px;
    text-align: start;

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      border-color: $azul;
      box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--unselected {
      opacity: 0.5;
    }

    .icon {
    }
    & a {
      color: $azul;
    }
  }

  &__input-csv {
    display: flex;
    justify-content: left;
    border: 1px solid $gray;
    padding: 7px 8px;
    text-align: start;    

    .csv-file-container {
      margin-right: 20px;
      width: 55px;
      a { 
        font-size: 12px;
      }
    }

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      border-color: $azul;
      box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--unselected {
      opacity: 0.5;
    }

    .icon {
    }
    & a {
      color: $azul;
    }
  }

  &__input-audio {
    border: 1px solid $gray;
    flex: 1 1;
    padding: 10px 15px;
    text-align: start;
    max-width: fit-content;

    audio {
      filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
      padding-top: 12px;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      border-color: $azul;
      box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--unselected {
      opacity: 0.5;
    }
  }
  &__input-video {
    line-height: 0;
    width: 100%;
    max-width: 240px;

    & .sample-inputs__sample-video-content {
      border: 6px solid transparent;
      width: 100%;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }

    &--selected {
      & .sample-inputs__sample-video-content {
        border-color: $azul;
        box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &--unselected {
      opacity: 0.5;
    }
  }
}
