@import "src/App";


.intro-tutorial-model-tasks-intro {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: $richNavy;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;
  padding-bottom: 200px;

  &__intro-text {
    text-align: center;
    color: $skyBlue;
    @include labelBold();
    margin-bottom: 14px;
  }

  &__title {
    text-align: center;
    @include billboardSmall;
    max-width: 1000px;
    color: $white;
    margin-bottom: 40px;
  }

  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }
}
