@import "src/App";

.model-card {
  background: $white;
  border: 2px solid $white;
  border-radius: 5px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.15);
  padding: 36px 24px 24px;
  overflow-wrap: break-word;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  position: relative;
  cursor: pointer;


  &:hover {
    transform: scale(103%);


  }

  &__icon {
    position: absolute;
    top: 8px;
    right: 12px;

    border: 1.5px solid $azul;
    border-radius: 50px;
    padding: 4px;
    width: 24px;
    height: 24px;


    path {
      fill: $richNavy;
    }

    &-minus {
      top: 8px;
      background-color: $azul;

      path {
        fill: $white;
      }
    }
  }


  &--active {
    border-color: $skyBlue;
  }

  &__tags-box {
    margin-top: 16px;
    gap: 8px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &-divider {
      width: 100%;
      border-bottom: 1px solid $smokeDarkest;
      margin: 12px 0;
    }

    &-item {
      @include bodySmall;
      color: $charcoalLightest;

      &-divider {
        color: $grayDarkest;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  &__divider {
    border: 1px solid $smokeDark;
    margin-bottom: 16px;
  }

  &__model-name {
    @include linkHeadline3;
    color: $almaMater;
    transition: color .3s;
    text-align: left;
    overflow-wrap: anywhere;
    @include safari {
      overflow-wrap: break-word;
    }

  }

  &__model-name:hover {
    color: $azulDark;
    text-decoration: underline;
  }

  &__model-description {
    @include bodySmall;
    color: $charcoalLightest;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  &__actions-box {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    @include tabletWidth {
      flex-direction: column;
      align-items: flex-end;
    }

  }

  &__try-model-button {
    margin-right: 24px;
  }


}


