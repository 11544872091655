@keyframes spin {
  0% {
    border-bottom-color: $azul;
    border-right-color: $azul;
    border-top-color: $azul;
    transform: rotate(0);
  }

  50% {
    border-bottom-color: $purple;
    border-right-color: $purple;
    border-top-color: $purple;
    transform: rotate(180deg);
  }

  100% {
    border-bottom-color: $azul;
    border-right-color: $azul;
    border-top-color: $azul;
    transform: rotate(359deg);
  }
}

@keyframes bounceUpAndRotate {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(90deg) translateX(-12px);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes expand {
  0% {
  }
  50% {

  }
  100% {
    transform: scale(105%);
  }
}
