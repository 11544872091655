.single-column-image-output {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 32px;

  @include desktopWidth {
    margin-left: 0;
    margin-right: 0;
  }

  &__header {
    border-bottom: 1px solid $smokeDarkest;
    margin-bottom: 24px;
    padding-bottom: 16px;

    @include tabletWidth {
      margin-bottom: 0;
    }

    &-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include maxWidth(575px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        .output-duration {
          margin-bottom: 12px;
        }
      }
    }

    &-duration {
      @include labelSmall;
      color: $charcoalLightest;

      &-seconds {
        text-transform: none;
      }
    }

    &-heading {
      @include headline3;
    }

    &-subheading {
      @include body;
      color: $charcoalLightest;
    }
  }

  &__back-link {
    @include secondaryButtonWithIconBefore("arrow-right.svg");
    max-width: 270px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
