@import "../../App";

@mixin pageNavButton {
  background: $white;
  border: 2px solid $grayDarkest;
  border-radius: 27px;
  color: $grayDarkest;
  margin: 0 4px;
  padding: 8px 16px;

  @include mobileWidth-lg {
    padding: 4px 8px;
  }
  transition: border-color .3s, color .3s;

  &:not(:disabled):hover {
    border-color: $gray;
    color: $grayDarker;
  }
}

@mixin prevNextButton {
  @include headline5;
  @include pageNavButton;

  align-items: center;
  display: flex;
  padding: 8px 24px;
  @include mobileWidth-lg {
    width: 155px;
  }
  @include mobileWidth-sm {
    width: 120px;
  }

  & > svg {
    height: 12px;
    transition: transform 0.3s;
    width: 13px;

    & > path {
      fill: $grayDarkest;
      transition: fill .3s;
    }
  }

  &:not(:disabled):hover {
    & > svg {
      transform: translateX(-6px);

      & > path {
        fill: $gray;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
  }
}

.page-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  @include desktopWidth {
    display: grid;
    grid-template-areas:
        "buttons buttons"
         "prev next";
    row-gap: 30px;
    &__page-buttons {
      grid-area: buttons;
      justify-content: center;
    }
    &__prev-button {
      grid-area: prev;
      justify-content: center;
    }
    &__next-button {
      grid-area: next;
      justify-content: center;
    }

  }
}

.page-nav__page-buttons {
  display: flex;
  padding: 0 4px
}

.page-nav__page-button {
  @include body;
  @include pageNavButton;

  &--selected {
    background-color: $grayDarkest;
    color: $white;

    &:hover {
      border-color: $grayDarkest;
    }
  }
}

.page-nav__placeholder {
  align-items: center;
  display: flex;
  padding: 0 4px;

  & > svg {
    height: 4px;

    & > path {
      fill: $grayDarkest;
    }
  }
}

.page-nav__prev-button {
  @include prevNextButton;

  & > svg {
    margin-right: 8px;
  }
}

.page-nav__next-button {
  @include prevNextButton;

  & > svg {
    margin-left: 8px;
    transform: rotate(180deg);
  }

  &:not(:disabled):hover {
    & > svg {
      transform: rotate(180deg) translateX(-6px);
    }
  }
}
