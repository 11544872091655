@import "../../../../../App";

.text-output, .text-to-code-output, 
.audio-to-text-output, .text-to-audio-output,
.text-conversation-output, .image-to-text-output, .audio-to-audio-output, .text-guided-image-to-image, .visual-question-answering, .text-to-video-output, .text-to-image-output {
  display: flex;
  flex-direction: row;
  gap: 72px;
  justify-content: space-between;
  width: 100%;

  @include maxWidth(1000px) {
    flex-direction: column;
    gap: 24px;
  }


  &__results, &__input {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: fit-content;
    padding: 32px;

    @include maxWidth(500px) {
      padding: 8px;
    }
  }

  &__input {
    &-container-text {
      @include body();
      border: 2px solid $grayDark;
      margin-top: 32px;
      min-height: 128px;
      padding: 16px 12px 12px;
      resize: vertical;
      width: 100%;

    }

    &-audio-content, &-image-content {
      padding-top: 12px;
      font-size: 20px;
    }

    &-submit-button {
      @include secondaryButton(before);

      align-items: center;
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 24px;
      max-width: fit-content;
    }
  }

  &__title-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include maxWidth(460px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__subtitle {
    @include body();
    color: $charcoalLightest;

  }

  &__output-container {
    margin-top: 32px;
    min-height: 128px;
    width: 100%;

    @include maxWidth(500px) {
      margin-top: 16px;
    }

    &-text {
      @include body();
    }

    &-background {
      background-color: $azulLightest;
      padding: 16px 12px 12px;
    }

    .output-audio-content {
      padding-top: 12px;
      font-size: 20px;
    }
  }

  .rating {
    background-color: $azulLightest;
    margin-top: 32px;
    padding: 16px;
  }
}
