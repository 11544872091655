@import "../../../App";

.semantic-segmentation-summary {
  background-color: $white;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding-top: 32px;
  border-top: 1px solid $smokeDarkest;
  width: 100%;
  margin-left: 0;

  @include maxWidth(1000px) {
  }

  &__header {
    width: 100%;
    border-bottom: 1px solid $smokeDarkest;
    padding-bottom: 16px;
    margin-bottom: 24px;

    &-heading {
      @include headline3;
    }

    &-subheading {
      color: $charcoalLightest;
      @include body;
    }
  }

  &__top-row {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__bottom-row {
    margin-top: 32px;

    &-btn {
      @include secondaryButtonWithIconBefore("arrow-right.svg");
      max-width: 270px;
    }

  }

  .semantic-segmentation-table {
    margin-top: 24px;
  }
}
