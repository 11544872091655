@import "src/App";

.text-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    @include body;
  }

  &__input {
    background: $white;
    margin-top: 24px;
    padding: 20px;

    &::placeholder {
      opacity: .9;
    }

    &-error {
      border-color: $darkRed;

      &:focus {
        border-color: $pink;
      }
    }
  }
}