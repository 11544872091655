@import "../../App";

.home-page-tasks {


  &__section-content {
    margin-top: 48px;
    box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.25);
    padding: 0 48px 48px;
    border-radius: 5px;
    background-color: $white;
    margin-bottom: 160px;

    @include tabletWidth {
      padding: 32px;
    }

    @include mobileWidth-lg {
      padding: 16px;
    }
  }

  &__selected-task {
    &-header {
      margin-bottom: 48px;
    }

    &-title {
      @include headline2;
      color: $richNavy;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-icon {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    &-description {
      margin-top: 8px;
      margin-bottom: 16px;
      @include body;
    }

    &-link {
      @include linkLarge;
      color: $azul;
      transition: 0.25s;

      &:hover {
        color: $purple;
      }
    }
  }


}
