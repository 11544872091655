@import "src/App";

.intro-tutorial-model-comparison-result {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 300px;
  position: relative;

  @include tabletWidth {
    padding-bottom: 200px;
  }

  &__intro-text {
    @include labelBold();
    color: $azul;
    margin-bottom: 4px;
    text-align: center;

  }

  &__title {
    @include billboardSmall;
    color: $richNavy;
    margin-bottom: 24px;
  }

  &__sub-title {
    @include body;
    color: $charcoalLightest;
    text-align: center;
    margin-bottom: 32px;
  }

  &__explanation {
    background-color: rgba($electricGreenLight, 0.2);
    padding: 16px 20px;
    display: flex;
    position: relative;
    max-width: 650px;
    margin: 40px 0 40px;
    border-radius: 10px;

    &-info {
      width: 20px;
      height: 20px;
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      margin-right: 12px;
      margin-top: 3px;
    }

    &-text {
      @include body;
      color: $charcoalLightest;

      @include mobileWidth-lg {
        @include bodySmall;
      }
    }
  }

  &__model-cards {
    display: flex;
    width: 100%;
    gap: 32px;
    justify-content: center;
    margin-top: 44px;

    @include maxWidth(600px) {
      flex-direction: column;
      align-items: center;

      gap: 40px;
    }

  }

  &__model-card {
    border: 3px solid;
    position: relative;
    width: 300px;
    padding: 14px 14px;

    &-success {
      border-color: $mintLight;
    }

    &-failure {
      border-color: $pinkLight;
    }

    &-icon {
      position: absolute;
      width: 64px;
      height: 64px;
      left: 38%;
      bottom: -33px;
      background: white;
      border-radius: 100px;
    }

    &-inner {
      background-color: $white;
      box-shadow: 0px 0px 15px -2px #00000040;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 12px;
      height: 275px;

    }

    &-title {
      @include headline3;
      color: $almaMater;
      text-align: center;
      margin-bottom: 12px;
    }

    &-output {
      width: 100%;
      background-color: rgba($azul, 0.03);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      border-radius: 5px;

      &-result {
        font-size: 36px;
        font-weight: 800;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: right;
        color: $charcoalLightest;
        margin-top: 12px;
      }

      &-title {
        @include headline3;
        color: $charcoalLightest;
        margin-top: -12px;
      }

      &-text {
        font-size: 36px;
        font-weight: 800;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: right;
        color: $charcoalLightest;
        margin-top: 12px;
      }
    }
  }

  &__cta {
    @include secondaryButtonWithIconAfter("arrow-right.svg");

    &:after {
      transform: translateX(0px) rotate(90deg);
    }

    &:not(:disabled) {
      &:hover:after {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }

  &__scroll-line {
    position: absolute;
    bottom: -250px;
    right: 37%;
    display: none;

    @include maxWidth(900px) {
      right: 50%;
    }
  }
}
