@import "src/App";

.experiment-inputs {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include tabletWidth {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .secondary-button {
    height: 48px;
    margin-left: auto;
    margin-bottom: 20px;
  }

  &__selection-area {
    margin-bottom: 32px;
    width: 100%;

    @include tabletWidth {
      max-width: 300px;
    }
  }
}
