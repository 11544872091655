@import "../../../../../App";

.classification-output,
.text-classification-output,
.audio-classification-output,
.video-classification-output {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 16px;

  padding: 32px;

  @include maxWidth(1000px) {
    margin-left: 0;
  }

  &__title {
    margin-bottom: 0;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include maxWidth(575px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        h3 {
          margin-top: 8px;
        }
      }
    }
  }

  &__subtitle {
    @include body;
    border-bottom: 1px solid $smokeDarkest;
    margin-bottom: 24px;
    padding-bottom: 16px;

    @include mobileWidth-lg {
      @include bodySmall;
    }
  }

  &__predictions {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    & > * + * {
      margin-top: 16px;
    }
  }
}
