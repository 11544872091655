@import "src/App";

.tutorial-with-side-nav {
  padding-left: 500px;
  padding-right: 40px;

  @include maxWidth(1500px) {
    padding-left: 400px;
  }

  @include maxWidth(1150px) {
    padding-left: 40px;
  }
}


.side-navigation {
  position: fixed;
  z-index: 15;
  top: 30vh;
  left: 150px;

  @include maxWidth(1500px) {
    left: 50px;

  }
  @include maxWidth(1150px) {
    display: none;
  }

  &__main-content {
    box-shadow: 0px 0px 15px -2px #00000040;
    background-color: $white;
    padding: 44px 30px;
    border-radius: 10px;
    position: fixed;
    z-index: 15;
    top: 30vh;
    left: 150px;

    @include maxWidth(1500px) {
      left: 50px;

    }
  }


  &__gradient-outline {
    position: absolute;
    left: -24px;
    border: 4px solid transparent;
    border-radius: 16px;
    z-index: 0;
    top: 20px;
    background-clip: padding-box;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      inset: 0;
      padding: 2px;
      border-radius: 15px;
      background: $primaryGradient;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

  }
}
