@import "../../App";


.home-page-main {
  @include paddingGutters;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 150px;

  &__background-image {
    background: url("../../resources/img/splash_bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
  }

  &__jump-link {
    position: absolute;
    bottom: 48px;
    @include headline5;
    color: $white;
    display: flex;
    align-items: center;
    transition: 0.25s;

    &:hover {
      color: $electricGreen;

      .home-page-main__jump-link-icon {
        animation: 750ms bounceUpAndRotate cubic-bezier(1, -0.13, .32, .89);
      }
    }

    &-icon {
      margin-left: 8px;
      width: 16px;
      height: 16px;
      transform: rotate(90deg);
      transition: 0.25s;


      path {
        fill: $electricGreen;
      }
    }
  }

  &__buttons {
    display: flex;

    > :first-child {
      margin-right: 24px;
    }

    @include tabletWidth {
      flex-direction: column;
      > :first-child {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
  }

  &__fun-gradient {
    animation: gradientAnimation 15s ease infinite;
    background: $secondaryGradient;
    background-size: 200% 200%;
    mix-blend-mode: overlay;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__tint {
    background: linear-gradient(90deg, #021C60 0%, rgba(30, 56, 119, 0) 100%);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }

  &__content-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px 0;
    position: relative;
    z-index: 1;

  }

  &__title {
    @include billboard;
    color: white;
    margin-bottom: 0;
    text-align: center;

  }

  &__divider {
    border-bottom: 6px solid $electricGreen;
    margin-bottom: 40px;
    margin-top: 32px;
    width: 355px;

    @include mobileWidth-lg {
      width: 230px;
    }
  }

  &__subtitle {
    @include bodyLarge;
    color: white;
    margin-bottom: 40px;
    text-align: center;
  }

  &__footer {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}
