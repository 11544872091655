@import "../../../App";

.quick-text-input {
  @include tabs;
  @include quick-input-container;

  &__subtitle {
    @include body;
    margin-top: 8px;
  }

  &__input {
    @include body();
    border: 2px solid $charcoalLightest;
    border-radius: 5px;
    margin-top: 16px;
    min-height: 128px;
    padding: 8px 16px;
    resize: vertical;
    width: 100%;
  }

  &__submit-button {
    @include primaryButtonWithIcon("after", "arrow-right-white.svg");

    align-items: center;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
  }
}