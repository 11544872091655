@import "src/App";

$csv-label-size: 18px;

.csv-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    @include body;
  }

  &__container {
    margin-top: 24px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &::placeholder {
      opacity: .9;
    }
  }

  &__csv-header {
    display: flex;
    flex-direction: row;
    padding-left: $csv-label-size  // Indentation equal to width of row labels
    
  }
  &__col-header {
    width: 151px;
    padding-left: 5px;
    border-top: 1px solid $charcoalLightest;
    border-left: 1px solid $charcoalLightest;
    background-color: $smokeDarkest;
    font-weight: 800;
  }
  &__col-header:last-of-type {
    width: 152px;
    border-right: 1px solid $charcoalLightest;
  }

  &__table {
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }
  &__row:first-child {
    p:first-of-type {
        border-top: 1px solid $charcoalLightest;
    }
  }
  &__row:first-of-type &__cell {
    border-top: 1px solid $charcoalLightest;
  }
  &__row:last-of-type {
    border-bottom: none;
  }
  &__row-label {
    width: $csv-label-size;
    background-color: $smokeDarkest;
    font-weight: 800;
    border-bottom: 1px solid $charcoalLightest;
    border-left: 1px solid $charcoalLightest;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cell {
    border-right: 1px solid $charcoalLightest;
    border-bottom: 1px solid $charcoalLightest;

    input {
        width: 150px;
        padding-left: 5px;
    }
  }
  &__cell:first-of-type {
    border-left: 1px solid $charcoalLightest;
  }

  &__download-csv-button {
    border: 1px solid $charcoalLightest;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px;
    font-weight: 600;
    color: $charcoalLightest;
    img {
        height: 50px;
    }
    p {
        margin-top: 5px;
        font-size: 12px;
    }
  }
}
