@import "../App";

:root {
  --primaryColor: #1A263A;
}

body {
  font-family: $sans-serif;
}

button {
  font-family: inherit;
}

input {
  @include bodySmall;
  border: 2px solid $smokeDark;
  border-radius: 5px;
  transition: border-color 0.3s;

  &:focus {
    outline: none !important;
    border-color: $azul;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
