@import "src/App";

.color-block, .bounding-box {
  padding: 12px;

  p, &__label {
    @include bodySmall;
    font-size: 14px;
    position: absolute;
    top: -24px;
    left: -3px;
    white-space: nowrap;
    padding: 0 4px;
    max-width: 150px;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 20;
  }
}

.bounding-box {
  position: relative;

  &__overlay {
    width: 100%;
    height: 100%;
    z-index: 30;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

}
