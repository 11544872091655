@import "../../../../../App";

.style-transfer {
    &__content {
        display: flex;
        flex-direction: row;

        .multi-input-preview {
            flex-grow: 1;
            &__container {
                display: flex;
                flex-direction: column;
                img {
                    margin-bottom: 16px;
                    max-width: 300px;
                }      
                @include maxWidth(700px) {
                    flex-direction: row;
                    justify-content: space-between;
                    img {
                        max-height: 200px;
                    }
                }                              
            }
        }
        @include maxWidth(700px) {
            display: flex;
            flex-direction: column;
        }
    }
    &__output {
        flex-grow: 1;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        padding: 32px;
      
        @include maxWidth(700px) {
          margin-left: 0;
        }     
        
        &-title-row {
            margin-bottom: 0;
        
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
    
            @include maxWidth(575px) {
            h3 {
                margin-top: 8px;
            }
            }
        }
        &-subtitle {
            @include body;
            border-bottom: 1px solid $smokeDarkest;
            margin-bottom: 24px;
            padding-bottom: 16px;
        
            @include mobileWidth-lg {
              @include bodySmall;
            }
        }   
        &-image {
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
            img {
                width: 70%;
            }
        }           
    }      
}